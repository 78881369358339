body {
  margin: 0;
  /* font-family: "Poppins", sans-serif; */
  background-color: "#FFFDF7";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.footer-nav-link-item {
  /* padding: 0 1em; */
  display: block;
  transition: background-color 0.15s;
  /* border-radius: 5px; */
}
/* .sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
} */

.sidebar-title {
  display: flex;
  font-size: 1rem;
  /* font-weight: bold; */
  justify-content: space-between;
}
.footer-nav-link-title {
  display: flex;
  font-size: 1rem;
  gap: 20px;
}
/* .sidebar-title span i {
  display: inline-block;
  width: 1.5em;
} */
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.footer-nav-link-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.footer-nav-link-item.open > .footer-nav-link-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.footer-nav-link-content {
  padding: 0.25em;
  padding-left:1em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}
.footer-nav-link-item.open > .footer-nav-link-content {
  height: auto;
}

.sidebar-item.plain {
  color: #2d2d2d;
  text-decoration: none;
  font-size: 1rem;
  /* font-weight: bold; */
}
.footer-nav-link-item.plain {
  color: #2d2d2d;
  text-decoration: none;
  font-size: 1rem;
  /* font-weight: bold; */
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}
.footer-nav-link-item.plain:hover {
  text-decoration: underline;
}
/* .sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
} */
